import React from "react"
import Seo from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const CookiesPage = () => {
  return (
    <>
      <Seo />
      <Layout>
        <Banner content="This is the cookies page, but there are no chocolate chips to be found here" />
        <p>
          Actually I'm not using any &#x1F36A; at the moment. Who knows, maybe
          one sunny day we'll add some beautiful client side identification for
          marvelous frontend!
        </p>
      </Layout>
    </>
  )
}

export default CookiesPage
